<template>
  <div class="print-bono">
    <div class="pos-bono text-center mt-2" v-if="bono.id != undefined">
      <div class="header">
        <table>
          <tbody>
            <tr>
              <th style="text-align:center !important">
                <h2>
                  BONO
                </h2>
              </th>
            </tr>
            <tr>
              <th style="text-align:center !important">
                <h2>
                  {{ bono.codigo }}
                </h2>
              </th>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="body">
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                Fecha
              </th>
              <td>
                {{
                  moment(bono.created_at, "YYYY-MM-DD HH:mm:ss").format("ll")
                }}
              </td>
            </tr>
            <tr>
              <th>
                Cliente
              </th>
              <td>
                {{ bono.cliente }}
              </td>
            </tr>
            <tr>
              <th>
                Nit:
              </th>
              <td>
                {{ bono.cc_cliente }}
              </td>
            </tr>
            <tr>
              <th>
                Valor bono
              </th>
              <td>
                {{ bono.valor | currency }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th></th>
              <th></th>
            </tr>
            <tr>
              <th>Efectivo</th>
              <th style="text-align:right !important">
                {{ bono.efectivo | currency }}
              </th>
            </tr>
            <tr>
              <th>Transferencia</th>
              <th style="text-align:right !important">
                {{ bono.transfer | currency }}
              </th>
            </tr>
            <tr>
              <th>Datafono</th>
              <th style="text-align:right !important">
                {{ bono.datafono | currency }}
              </th>
            </tr>
          </tfoot>
        </table>
        <hr />
        <table>
          <tbody>
            <tr>
              <th colspan="2" style="text-align:center !important">
                <h2>
                  BONO
                </h2>
              </th>
            </tr>
            <tr>
              <th colspan="2" style="text-align:center !important">
                <h2>
                  {{ bono.codigo }}
                </h2>
              </th>
            </tr>
            <tr>
              <th>Cliente</th>
              <td>
                {{ bono.cliente }}
              </td>
            </tr>
            <tr>
              <th>
                Nit
              </th>
              <td>
                {{ bono.cc_cliente }}
              </td>
            </tr>
            <tr>
              <th>
                Valor bono
              </th>
              <td>
                {{ bono.valor | currency }}
              </td>
            </tr>
            <tr>
              <th>
                Fecha
              </th>
              <td>
                {{
                  moment(bono.created_at, "YYYY-MM-DD HH:mm:ss").format("ll")
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <v-row>
      <v-col>
        <v-btn dark color="black" class="ml-2 d-print-none" @click="print()"
          >Imprimir</v-btn
        >
        <v-btn color="primary" class="ml-2 d-print-none" @click="close()"
          >Cerrar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "printBono",
  data: () => ({
    bono: {},
  }),
  methods: {
    loadDetalleBono() {
      const body = {
        route: "/detalle_bono",
        params: {
          id: this.$route.params.id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.bono = response.data.data;
      });
    },
    moment(data, format) {
      return moment(data, format);
    },
    close() {
      window.close();
    },
    print() {
      window.print();
    },
  },
  created() {
    this.loadDetalleBono();
  },
  mounted() {
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
};
</script>
<style scoped>
.pos-bono {
  font-family: Arial;
  max-width: 80mm;
  margin: 0 auto;
  background: white;
  line-height: normal !important;
  color: black !important;
}
.header {
  font-weight: bold;
}
table {
  width: 100%;
  padding: 8px;
}
table thead th,
tfoot th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
table td,
th {
  font-size: 14px !important;
}

table th {
  text-align: left !important;
}
table td {
  text-align: right !important;
}
</style>
